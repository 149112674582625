<template>
    <modal-pro></modal-pro>
    <router-link :to="{name : 'PS'}" class="section-full section-ps"></router-link>
    <router-link :to="{name : 'VA'}" class="section-full section-va"></router-link>
    <router-link :to="{name : 'LC'}" class="section-full section-lc"></router-link>
   <router-link :to="{name : 'CC'}" class="section-full section-cc"></router-link>
</template>

<script>
import ModalPro from "./../components/ModalPro.vue";

export default {  
  name: "Products",
  components : { ModalPro }
}
</script>

<style scoped lang="scss">

.section-ps {
  background-image: url("./../assets/products-bg-ps.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.section-va {
  background-image: url("./../assets/products-bg-va.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.section-lc {
  background-image: url("./../assets/products-bg-lc.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.section-cc {
  background-image: url("./../assets/products-bg-cc.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

@media screen and (max-width:768px) {
  .section-full {
    min-height: 300px;
    background-position: left -20px center;
  }
}
</style>